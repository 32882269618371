import { Grid } from '@lmc-eu/spirit-web-react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import FeatureToggle from '@components/utilities/FeatureToggle/FeatureToggle';
import HtmlParser from '@components/utilities/HtmlParser';
import LanguageSwitcher from '@components/utilities/LanguageSwitcher/LanguageSwitcher';
import LogoHorizontalBlackAndWhiteSvg from '@icons/logo-horizontal-black-and-white.svg';
import { useGenerateRoute, useGetLocalizedRoute } from '@libs/localizedRoute';
import { Route } from '@libs/routes';
import { ContactsForDomain } from 'graphql/generated/types/graphqlTypes';
import classes from './Footer.module.scss';

interface Props {
  contactsForDomain: ContactsForDomain;
}

const Footer = ({ contactsForDomain }: Props) => {
  const { t } = useTranslation(['front', 'url', 'messages']);
  const generateRoute = useGenerateRoute();
  const getLocalizedRoute = useGetLocalizedRoute();

  const links = [
    { title: t('MORE_ABOUT_ATMOSKOP'), url: getLocalizedRoute(Route.introduction) },
    { title: t('CODEX'), url: t('CODEX_URL', { ns: 'url' }) },
    { title: t('FOOTER_PRIVACY_PROTECTION'), url: t('PRIVACY_PROTECTION_URL', { ns: 'url' }) },
    { title: t('TERMS_OF_USE'), url: t('TERMS_OF_SERVICE_URL', { ns: 'url' }) },
    { title: t('FOOTER_BUTTON_COMPANY_FAQ'), url: getLocalizedRoute(Route.informationForCompanies) },
    {
      title: t('ADVERTISING_AND_TRANSPARENCY_ON_ALMA_PORTALS'),
      url: t('ADVERTISING_AND_TRANSPARENCY_ON_ALMA_PORTALS_URL', { ns: 'url' }),
    },
  ];

  return (
    <div className={classes.Footer}>
      <div className={classes.Footer__container}>
        <Grid cols={1} tablet={2}>
          <div>
            <div className={classes.Footer__title}>{t('ABOUT_ATMOSKOP')}</div>
            {links.map((link, index) => (
              <div className="mb-400" key={`footer_links_${index}`}>
                <Link href={link.url}>{link.title}</Link>
              </div>
            ))}
            <div className="mb-400" key={`footer_links_cookie_settings`}>
              <a href="" data-cc="c-settings">
                {t('COOKIE_SETTINGS')}
              </a>
            </div>
          </div>
          <div>
            <div className={classes.Footer__title}>{t('CONTACTS')}</div>
            <div className="mb-500">
              {t('CALL')} {t('IN_WORKING_DAYS_FROM_TO_TO_PHONE_NUMBER')}
              <br />
              <br />
              <HtmlParser
                htmlString={t('FOOTER_CUSTOMER_CARE_PHONE', {
                  customerCarePhoneHref: contactsForDomain.customerCarePhone.replace(/ /g, ''),
                  customerCarePhone: contactsForDomain.customerCarePhone,
                })}
              />
            </div>
            <div className="mb-500">
              {t('WRITE')} {t('EVERYTIME_TO')}{' '}
              <a href={`mailto:${contactsForDomain.customerCareEmail}`}>{contactsForDomain.customerCareEmail}</a>
            </div>
            <div className="mb-500">
              <a href={t('CONTACTS_URL', { ns: 'url' })} target="_blank">
                {t('CONTACTS_FOOTER')}
              </a>
            </div>
            <FeatureToggle identifier="feature_show_language_switcher">
              <div className={classes.Footer__title}>{t('LANGUAGE')}</div>
              <LanguageSwitcher />
            </FeatureToggle>
          </div>
        </Grid>
      </div>
      <div className="text-center">
        <a href={generateRoute('homepage')} className="d-inline-block mb-700">
          <LogoHorizontalBlackAndWhiteSvg />
        </a>
        <HtmlParser htmlString={`${t('FOOTER_MOTTO')}`} className="typography-body-small-text-regular" />
      </div>
    </div>
  );
};

export default Footer;
