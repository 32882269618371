export const B2bProfileVisualContentAdministrationController = {
    "VISUAL_CONTENT_HELP_TEXTS_BY_TYPE": {
        "photo": "COVER_DESCRIPTION",
        "hero": "HERO_DESCRIPTION",
        "summary": "SUMMARY_DESCRIPTION",
        "comments": "COMMENTS_DESCRIPTION",
        "culture": "CULTURE_DESCRIPTION"
    },
    "PHOTO_ASPECT_RATIO_BY_VISUAL_CONTENT_TYPE": {
        "photo": 3.2,
        "hero": 1.7777777777778,
        "summary": 1.7777777777778,
        "comments": 1.7777777777778,
        "culture": 1.7777777777778,
        "showcase": 1.3333333333333
    },
    "HERO_SECTION_PLACEHOLDER_LETTERS": [
        "A",
        "B",
        "C"
    ],
    "SUMMARY_SECTION_PLACEHOLDER_LETTERS": [
        "D",
        "E",
        "F"
    ],
    "COMMENTS_SECTION_PLACEHOLDER_LETTERS": [
        "G",
        "H",
        "I"
    ],
    "ATMOSPHERE_SECTION_PLACEHOLDER_LETTERS": [
        "J",
        "K",
        "L"
    ],
    "PROFILE_VISUAL_CONTENT_TYPE_TO_SECTION_PLACEHOLDER_LETTERS": {
        "hero": [
            "A",
            "B",
            "C"
        ],
        "summary": [
            "D",
            "E",
            "F"
        ],
        "comments": [
            "G",
            "H",
            "I"
        ],
        "culture": [
            "J",
            "K",
            "L"
        ]
    },
    "GALLERY_IMAGE_BOX_WIDTH": 400,
    "ALLOWED_MIME_TYPES_FOR_GALLERY": [
        "image\/jpeg",
        "image\/png",
        "image\/webp"
    ],
    "MIN_UPLOAD_FILE_IMAGE_WIDTH_IN_PIXELS": 1920,
    "MIN_UPLOAD_FILE_IMAGE_HEIGHT_IN_PIXELS": 1080,
    "MIN_COVER_UPLOAD_FILE_IMAGE_WIDTH_IN_PIXELS": 1440,
    "MIN_COVER_UPLOAD_FILE_IMAGE_HEIGHT_IN_PIXELS": 450
} as const;
export const CookieFactory = {
    "DAYS_TO_SAVE_USER_MATCHING_PREFERENCES_TOKEN": 7
} as const;
export const CookiesConstants = {
    "DEBUG_COOKIE": "atm_dbg",
    "PHPUNIT_MOCK_SESSION": "MOCKSESSID",
    "LMC_USER_IDENTIFIER_COOKIE": "lmc_visitor",
    "LMC_COOKIE_CONSENT_MANAGER_COOKIE": "lmc_ccm",
    "ATMOSKOP_SESSION_ID_COOKIE": "APATMOSKOPID",
    "PHP_SESSION_ID_COOKIE": "PHPSESSID",
    "REMEMBER_ME_LOGIN_COOKIE": "REMEMBERME",
    "REDIRECT_TO_NEW_REACT_PROFILE": "redirect-to-new-react-profile",
    "USER_MATCHING_PREFERENCES_HASH": "user-matching-preferences-hash",
    "PROFILE_FEEDBACK_GIVEN_COOKIE_PREFIX": "profileFeedbackBar-",
    "PROFILE_FEEDBACK_COOKIE_NAME_PREFIX": "feedbackGivenProfileIds-",
    "FLOATING_ACTION_BUTTON_COOKIE_PREFIX": "floatingActionButton-",
    "SUBSCRIBED_EMAIL_ADDRESS_FOR_NOTIFICATIONS_COOKIE": "subscribedEmailAddressForNotifications",
    "REGISTRATION_WALL_VISITED_COMPANY_COOKIE_NAME": "registrationWallVisitedCompany",
    "MEDIUM_REGISTRATION_WALL_COUNTDOWN_IS_OVER_COOKIE_NAME": "mediumRegistrationWallCountdownIsOver",
    "CUSTOM_GTM_TAG_SESSION_ID": "_session_UA",
    "CUSTOM_GTM_TAG_TABCOUNT": "tabcount",
    "CUSTOM_GTM_TAG_TABID": "tabid",
    "CUSTOM_GTM_TAG_TABTYPE": "tabtype",
    "FACEBOOK_PIXEL_COOKIE": "_fbp",
    "GOOGLE_ADSENSE_COOKIE_PREFIX": "_gcl",
    "GOOGLE_ANALYTICS_USER_IDENTIFIER_COOKIE": "_ga",
    "GOOGLE_ANALYTICS_USER_IDENTIFIER_COOKIE_2": "_gid",
    "GOOGLE_DOUBLE_CLICK": "_dc_gtm",
    "GOOGLE_TAG_MANAGER_DEBUGGING_PREFIX": "gtm",
    "HOTJAR_COOKIE_PREFIX": "_hj",
    "HOTJAR_VIEWPORT_ID": "hjViewportId",
    "INTERCOM_ID_COOKIE_PREFIX": "intercom-id-",
    "INTERCOM_SESSION_COOKIE_PREFIX": "intercom-session-",
    "AB_TASTY": "ABTasty",
    "AB_TASTY_SESSION": "ABTastySession",
    "AB_TASTY_DOMAIN_TEST": "ABTastyDomainTest",
    "AB_TASTY_DEBUG": "abTastyDebug"
} as const;
export const CookiesConsentCategories = {
    "NECESSARY_COOKIES_CATEGORY": "necessary",
    "AD_COOKIES_CATEGORY": "ad",
    "ANALYTICS_COOKIES_CATEGORY": "analytics",
    "FUNCTIONALITY_COOKIES_CATEGORY": "functionality",
    "PERSONALIZATION_COOKIES_CATEGORY": "personalization",
    "NECESSARY_COOKIES": [
        "APATMOSKOPID",
        "atm_dbg",
        "gtm",
        "lmc_ccm",
        "PHPSESSID",
        "MOCKSESSID",
        "user-matching-preferences-hash",
        "sf_redirect"
    ],
    "AD_COOKIES": [
        "_fbp",
        "_gcl",
        "_dc_gtm"
    ],
    "ANALYTICS_COOKIES": [
        "_session_UA",
        "tabcount",
        "tabid",
        "tabtype",
        "_ga",
        "_gid",
        "hjViewportId",
        "lmc_visitor",
        "registrationWallVisitedCompany",
        "mediumRegistrationWallCountdownIsOver"
    ],
    "FUNCTIONALITY_COOKIES": [
        "REMEMBERME",
        "profileFeedbackBar-",
        "feedbackGivenProfileIds-",
        "floatingActionButton-",
        "subscribedEmailAddressForNotifications",
        "redirect-to-new-react-profile",
        "ABTasty",
        "abTastyDebug",
        "ABTastySession",
        "ABTastyDomainTest"
    ],
    "PERSONALIZATION_COOKIES": [
        "_hj"
    ],
    "INTERCOM_COOKIES": [
        "intercom-id-",
        "intercom-session-"
    ]
} as const;
export const FeatureToggleConstants = {
    "FEATURE_ASK_REVIEWER_FOR_GDPR_CONSENT": "feature_ask_reviewer_for_gdpr_consent",
    "FEATURE_COOKIES_CONSENT_BAR": "feature_cookies_consent_bar",
    "FEATURE_KEY_ALTERNATIVE_COMPANY_LANDING_PAGE": "feature_alternative_company_landing_page",
    "FEATURE_KEY_COMPANY_LIST": "feature_company_list",
    "FEATURE_KEY_EXCHANGE_DATA_WITH_OTHER_BRANDS": "exchange_data_with_other_brands",
    "FEATURE_KEY_EXPORT_AS_INTERESTING_PROFILE_FOR_JOBS": "feature_export_as_interesting_profile_for_jobs",
    "FEATURE_KEY_HOMEPAGE_HEADER_SHOW_JOB_DESCRIPTIONS_BUTTON": "feature_homepage_header_show_job_descriptions_button",
    "FEATURE_KEY_HOMEPAGE_HEADER_SHOW_PROFILE_LIST_ANCHOR": "feature_homepage_header_show_profile_list_anchor",
    "FEATURE_KEY_HOMEPAGE_HEADER_SHOW_PROFILE_SEARCH_BUTTON": "feature_homepage_header_show_profile_search_button",
    "FEATURE_KEY_HOMEPAGE_HEADER_SHOW_INTRODUCTION_BUTTON": "feature_homepage_header_show_introduction_button",
    "FEATURE_KEY_HOMEPAGE_MEDALLIONS_PROMOTE_BY_REVIEWS_COUNT": "feature_homepage_medallions_promote_by_reviews_count",
    "FEATURE_KEY_INTERCOM": "feature_intercom",
    "FEATURE_KEY_LAUNCH_FINAL_PHASE": "feature_all_pages",
    "FEATURE_KEY_LINKS_TO_PROFILE_GALLERY_ON_PROFILE": "links_to_profile_gallery_on_profile",
    "FEATURE_KEY_LINKS_TO_QUESTIONNAIRE_WITH_PROFILE_SEARCH": "links_to_questionnaire_with_profile_search",
    "FEATURE_KEY_LINKS_TO_SPECIFIC_QUESTIONNAIRE_ON_PROFILE": "links_to_specific_questionnaire_on_profile",
    "FEATURE_KEY_PROFILE_FEEDBACK_BAR": "profile_feedback_bar",
    "FEATURE_KEY_QUESTIONNAIRE_REACTION": "feature_questionnaire_reaction",
    "FEATURE_KEY_SENDING_B2B_REPORTS": "sending_b2b_reports",
    "FEATURE_KEY_USE_SUPER_ADEN_FOR_JOB_DESCRIPTION_DETAIL": "feature_use_super_aden_for_job_description_detail",
    "FEATURE_KEY_WHAT_MATTERS_MOST_TO_ME": "feature_what_matters_most_to_me",
    "FEATURE_USE_COMPANY_LANDING_PAGE_FOR_DISABLED_PAGES": "feature_use_company_landing_page_for_disabled_pages",
    "FEATURE_KEY_SHOW_INTERESTING_PREMIUM_PROFILES_ON_HOMEPAGE": "feature_show_interesting_premium_profiles_on_homepage",
    "FEATURE_KEY_SHOW_EXAMPLES_LINK_IN_B2B_REPORT_NOTIFICATION": "feature_show_examples_link_in_b2b_report_notification",
    "FEATURE_KEY_SHOW_LANGUAGE_SWITCHER": "feature_show_language_switcher",
    "FEATURE_KEY_SHOW_TYPEFORM_ENTRY_POINT_MISSING_COMPANY": "feature_show_typeform_entry_point_missing_company",
    "FEATURE_KEY_ENABLE_OUTLINKS_ON_PROFILE": "feature_enable_outlinks_on_profile",
    "FEATURE_KEY_SHOW_PROFILE_SHOWCASE": "feature_profile_showcase",
    "CONFIG_FEATURE_SHOW_BRAND_PROMO_SECTION_IN_FOOTER": "show_brand_promo_section_in_footer",
    "CONFIG_FEATURE_SHOW_PROFILE_UPSELL_PROMOTION": "show_profile_upsell_promotion",
    "CONFIG_FEATURE_SHOW_LINKS_TO_JOB_DESCRIPTIONS": "show_links_to_job_descriptions",
    "CONFIG_FEATURE_SHOW_UNCONFIRMED_REVIEWS_ENABLED_DEFAULT": "show_unconfirmed_reviews_enabled_default",
    "CONFIG_FEATURE_REPLACE_ADD_REVIEW_BUTTON_IN_MENU_ON_PROFILE": "replace_add_review_button_in_menu_on_profile",
    "CONFIG_FEATURE_SHOW_RECOMMENDATIONS_IN_SEARCH": "show_recommendations_in_search",
    "CONFIG_FEATURE_SHOW_SPECIAL_COMPANY_LIST_LINKS": "show_special_company_list_links",
    "CONFIG_FEATURE_SHOW_REGION_FILTER_IN_COMPANY_LIST": "show_region_filter_in_company_list",
    "CONFIG_FEATURE_SHOW_SUITABLE_FOR_PEOPLE_FROM_UKRAINE_FILTER": "show_suitable_for_people_from_ukraine_filter"
} as const;
export const FieldOfWorkId = {
    "OPTION_FIELD_ADMINISTRATION": 1,
    "OPTION_FIELD_SECURITY": 2,
    "OPTION_FIELD_FINANCE": 3,
    "OPTION_FIELD_IT": 4,
    "OPTION_FIELD_QUALITY": 5,
    "OPTION_FIELD_LOGISTICS": 6,
    "OPTION_FIELD_MANAGEMENT": 7,
    "OPTION_FIELD_MARKETING": 8,
    "OPTION_FIELD_SHOPPING": 9,
    "OPTION_FIELD_BUSINESS": 10,
    "OPTION_FIELD_SALES": 11,
    "OPTION_FIELD_HR": 12,
    "OPTION_FIELD_LAW": 13,
    "OPTION_FIELD_CONSTRUCTION": 14,
    "OPTION_FIELD_PRODUCTION": 15,
    "OPTION_FIELD_RESEARCH": 16,
    "OPTION_FIELD_CUSTOMER_SERVICE": 17,
    "OPTION_FIELD_HEALTH_CARE": 18,
    "OPTION_FIELD_OTHERS": 19,
    "URL_SLUGS": {
        "1": "administrativa",
        "2": "bezpecnost-ostraha-ozbrojene-slozky",
        "3": "finance-ucetnictvi",
        "4": "it-vyvoj-software",
        "5": "kvalita-kontrola-kvality",
        "6": "logistika-doprava",
        "7": "management",
        "8": "marketing",
        "9": "nakup",
        "10": "obchodnik",
        "11": "obsluha-zakazniku-prodej-na-prodejne",
        "12": "personalistika-hr",
        "13": "pravo",
        "14": "stavebni-prace",
        "15": "vyroba",
        "16": "vyzkum-vyvoj",
        "17": "zakaznicka-podpora",
        "18": "zdravotni-pece",
        "19": "jine"
    }
} as const;
export const IndexedJobDescriptionFilterValues = {
    "INTERESTS": {
        "200900001": "Administrativa",
        "201100017": "Asistent\/asistentka",
        "201100002": "Administrativn\u00ed pracovn\u00edk",
        "201100652": "Asistent pedagoga",
        "201100358": "\u00da\u010detn\u00ed",
        "201100562": "Developer",
        "201100014": "Architekt",
        "201100263": "Realitn\u00ed makl\u00e9\u0159",
        "201100003": "Advok\u00e1t",
        "201100178": "Not\u00e1\u0159",
        "201100527": "Copywriter",
        "201100237": "Pr\u00e1vn\u00edk",
        "201100661": "Scrum master",
        "201100065": "Finan\u010dn\u00ed poradce",
        "201100251": "Projektant",
        "201100248": "Project Manager",
        "201100472": "Konzultant",
        "201100038": "Da\u0148ov\u00fd poradce\/specialista",
        "201100048": "Ekonom",
        "201100058": "Fakturant\/fakturantka",
        "201100566": "Technolog",
        "201100597": "Product manager",
        "201100001": "Account Manager",
        "201100614": "Business analytik",
        "201100180": "Obchodn\u00ed \u0159editel\/mana\u017eer",
        "201100156": "Marketingov\u00fd \u0159editel\/mana\u017eer",
        "201100449": "Technick\u00fd \u0159editel\/mana\u017eer",
        "201100573": "\u0158editel",
        "201100170": "N\u00e1kup\u010d\u00ed",
        "201100619": "Datov\u00fd analytik",
        "201100608": "Webov\u00fd analytik",
        "200900017": "Marketing",
        "201100606": "Product owner",
        "201100006": "IT analytik",
        "201100122": "IT konzultant",
        "201100249": "Program\u00e1tor",
        "201100076": "Grafik",
        "201100434": "Vedouc\u00ed v\u00fdroby",
        "201100582": "UX designer",
        "201100433": "Tester",
        "201100067": "Fotograf",
        "201100259": "Psycholog",
        "201100141": "L\u00e9k\u00e1rn\u00edk",
        "201100019": "Auditor",
        "201100092": "Jednatel",
        "201100207": "Personalista",
        "201100032": "Chemik",
        "201100265": "Novin\u00e1\u0159, publicista, redaktor",
        "201100420": "Oper\u00e1tor",
        "201100168": "N\u00e1bor\u00e1\u0159"
    },
    "FULLTEXT_ITEMS": [
        "v\u00fdvoj\u00e1\u0159",
        "IT",
        "HR"
    ],
    "LOCALITIES_PARAMETERS": [
        {
            "label": "Praha-centrum",
            "latitude": "50.08455175080471",
            "longitude": "14.417781898902357",
            "radius": "20"
        },
        {
            "label": "Brno, okres Brno-m\u011bsto",
            "latitude": "49.191864177059585",
            "longitude": "16.611085007483528",
            "radius": "20"
        },
        {
            "label": "Ostrava, okres Ostrava-m\u011bsto",
            "latitude": "49.834645200004076",
            "longitude": "18.28204407915876",
            "radius": "20"
        },
        {
            "label": "Plze\u0148, okres Plze\u0148-m\u011bsto",
            "latitude": "49.747474819071996",
            "longitude": "13.377587405658643",
            "radius": "20"
        },
        {
            "label": "Liberec",
            "latitude": "50.76999730542042",
            "longitude": "15.058449101059333",
            "radius": "20"
        },
        {
            "label": "Olomouc",
            "latitude": "49.59386869359883",
            "longitude": "17.250870573261892",
            "radius": "20"
        },
        {
            "label": "\u010cesk\u00e9 Bud\u011bjovice",
            "latitude": "48.97446842953373",
            "longitude": "14.474340555858472",
            "radius": "20"
        },
        {
            "label": "Hradec Kr\u00e1lov\u00e9",
            "latitude": "50.20922841272862",
            "longitude": "15.832768465711828",
            "radius": "20"
        },
        {
            "label": "Pardubice",
            "latitude": "50.03853842894462",
            "longitude": "15.780205484914024",
            "radius": "20"
        },
        {
            "label": "\u00dast\u00ed nad Labem",
            "latitude": "50.660487522975316",
            "longitude": "14.040692716010637",
            "radius": "20"
        },
        {
            "label": "Zl\u00edn",
            "latitude": "49.22665421401869",
            "longitude": "17.666333847403124",
            "radius": "20"
        },
        {
            "label": "Hav\u00ed\u0159ov, okres Karvin\u00e1",
            "latitude": "49.779844785867155",
            "longitude": "18.436879856699473",
            "radius": "20"
        },
        {
            "label": "Kladno",
            "latitude": "50.147335564972295",
            "longitude": "14.10285088807273",
            "radius": "20"
        },
        {
            "label": "Most",
            "latitude": "50.50300690703843",
            "longitude": "13.636174070582527",
            "radius": "20"
        },
        {
            "label": "Opava",
            "latitude": "49.938663495044764",
            "longitude": "17.90256996274353",
            "radius": "20"
        },
        {
            "label": "Fr\u00fddek-M\u00edstek",
            "latitude": "49.685366221539574",
            "longitude": "18.348376755350024",
            "radius": "20"
        },
        {
            "label": "Jihlava",
            "latitude": "49.39610007905096",
            "longitude": "15.591244851804882",
            "radius": "20"
        },
        {
            "label": "Teplice",
            "latitude": "50.640397510189516",
            "longitude": "13.824507307558434",
            "radius": "20"
        },
        {
            "label": "Karvin\u00e1",
            "latitude": "49.854003092827654",
            "longitude": "18.541689823757494",
            "radius": "20"
        },
        {
            "label": "Karlovy Vary",
            "latitude": "50.22131722434975",
            "longitude": "12.886072636802139",
            "radius": "20"
        },
        {
            "label": "D\u011b\u010d\u00edn",
            "latitude": "50.78215251819736",
            "longitude": "14.21478126002133",
            "radius": "20"
        },
        {
            "label": "Chomutov",
            "latitude": "50.46047542440491",
            "longitude": "13.41778558184212",
            "radius": "20"
        },
        {
            "label": "Jablonec nad Nisou",
            "latitude": "50.72430761844869",
            "longitude": "15.17107709187573",
            "radius": "20"
        },
        {
            "label": "Mlad\u00e1 Boleslav",
            "latitude": "50.41135132403158",
            "longitude": "14.903184958744193",
            "radius": "20"
        },
        {
            "label": "Prost\u011bjov",
            "latitude": "49.47187836479972",
            "longitude": "17.111839696126417",
            "radius": "20"
        },
        {
            "label": "P\u0159erov",
            "latitude": "49.45510903565941",
            "longitude": "17.450901992221237",
            "radius": "20"
        },
        {
            "label": "\u010cesk\u00e1 L\u00edpa",
            "latitude": "50.68551316830435",
            "longitude": "14.53764166096575",
            "radius": "20"
        },
        {
            "label": "T\u0159eb\u00ed\u010d",
            "latitude": "49.21492279908352",
            "longitude": "15.881657349425964",
            "radius": "20"
        },
        {
            "label": "T\u0159inec, okres Fr\u00fddek-M\u00edstek",
            "latitude": "49.677632300780616",
            "longitude": "18.670779701195485",
            "radius": "20"
        },
        {
            "label": "T\u00e1bor",
            "latitude": "49.41441245951205",
            "longitude": "14.6578033918673",
            "radius": "20"
        },
        {
            "label": "Znojmo",
            "latitude": "48.85549873998131",
            "longitude": "16.04879761776127",
            "radius": "20"
        },
        {
            "label": "Kol\u00edn",
            "latitude": "50.02813618267",
            "longitude": "15.200567805826033",
            "radius": "20"
        },
        {
            "label": "P\u0159\u00edbram",
            "latitude": "49.689877391946375",
            "longitude": "14.010425227860479",
            "radius": "20"
        },
        {
            "label": "Cheb",
            "latitude": "50.079634610527044",
            "longitude": "12.373921808285798",
            "radius": "20"
        },
        {
            "label": "P\u00edsek",
            "latitude": "49.30879533068927",
            "longitude": "14.147502862241444",
            "radius": "20"
        },
        {
            "label": "Trutnov",
            "latitude": "50.56100669071267",
            "longitude": "15.912703713891108",
            "radius": "20"
        },
        {
            "label": "Krom\u011b\u0159\u00ed\u017e",
            "latitude": "49.29785144202675",
            "longitude": "17.393116256261933",
            "radius": "20"
        },
        {
            "label": "Orlov\u00e1, okres Karvin\u00e1",
            "latitude": "49.84526588351289",
            "longitude": "18.43011000798204",
            "radius": "20"
        },
        {
            "label": "Vset\u00edn",
            "latitude": "49.33870885768843",
            "longitude": "17.99619027435709",
            "radius": "20"
        },
        {
            "label": "\u0160umperk",
            "latitude": "49.96528375806657",
            "longitude": "16.97060803148561",
            "radius": "20"
        },
        {
            "label": "Uhersk\u00e9 Hradi\u0161t\u011b",
            "latitude": "49.06974978742326",
            "longitude": "17.459685525695896",
            "radius": "20"
        },
        {
            "label": "B\u0159eclav",
            "latitude": "48.75896858819177",
            "longitude": "16.882030010054084",
            "radius": "20"
        },
        {
            "label": "Hodon\u00edn",
            "latitude": "48.84893429776181",
            "longitude": "17.132444213130064",
            "radius": "20"
        },
        {
            "label": "\u010cesk\u00fd T\u011b\u0161\u00edn, okres Karvin\u00e1",
            "latitude": "49.74613297327782",
            "longitude": "18.626131298030312",
            "radius": "20"
        },
        {
            "label": "Chrudim",
            "latitude": "49.951092277407476",
            "longitude": "15.79557574219703",
            "radius": "20"
        },
        {
            "label": "Havl\u00ed\u010dk\u016fv Brod",
            "latitude": "49.60784931831003",
            "longitude": "15.580686427311148",
            "radius": "20"
        },
        {
            "label": "Litom\u011b\u0159ice",
            "latitude": "50.53347819925138",
            "longitude": "14.13180212413998",
            "radius": "20"
        },
        {
            "label": "Nov\u00fd Ji\u010d\u00edn",
            "latitude": "49.59437554529146",
            "longitude": "18.010275828900156",
            "radius": "20"
        },
        {
            "label": "Krnov, okres Brunt\u00e1l",
            "latitude": "50.08966852320421",
            "longitude": "17.703846915672802",
            "radius": "20"
        },
        {
            "label": "Litv\u00ednov, okres Most",
            "latitude": "50.600686437199165",
            "longitude": "13.611110774666495",
            "radius": "20"
        },
        {
            "label": "Vala\u0161sk\u00e9 Mezi\u0159\u00ed\u010d\u00ed, okres Vset\u00edn",
            "latitude": "49.47180526506562",
            "longitude": "17.971127324099054",
            "radius": "20"
        },
        {
            "label": "Strakonice",
            "latitude": "49.26140655781078",
            "longitude": "13.902371309708663",
            "radius": "20"
        },
        {
            "label": "Klatovy",
            "latitude": "49.395520092845224",
            "longitude": "13.29505490856899",
            "radius": "20"
        },
        {
            "label": "Sokolov",
            "latitude": "50.1812978842267",
            "longitude": "12.640102170865227",
            "radius": "20"
        },
        {
            "label": "Kop\u0159ivnice, okres Nov\u00fd Ji\u010d\u00edn",
            "latitude": "49.599471324457966",
            "longitude": "18.14480056790135",
            "radius": "20"
        },
        {
            "label": "Kutn\u00e1 Hora",
            "latitude": "49.94838847757286",
            "longitude": "15.268164652022707",
            "radius": "20"
        },
        {
            "label": "Jind\u0159ich\u016fv Hradec",
            "latitude": "49.14404471851941",
            "longitude": "15.003007696449204",
            "radius": "20"
        },
        {
            "label": "Beroun",
            "latitude": "49.96382341175781",
            "longitude": "14.071996488732408",
            "radius": "20"
        },
        {
            "label": "Bohum\u00edn, okres Karvin\u00e1",
            "latitude": "49.90410621997427",
            "longitude": "18.357546197747403",
            "radius": "20"
        },
        {
            "label": "\u017d\u010f\u00e1r nad S\u00e1zavou",
            "latitude": "49.56263349531153",
            "longitude": "15.939240585654872",
            "radius": "20"
        }
    ]
} as const;
export const InterestingProfilesGalleryController = {
    "PROFILE_CARD_LOGO_WIDTH": 144,
    "PROFILE_CARD_PHOTO_WIDTH": 730,
    "FULL_GALLERY_PROFILES_COUNT": 15,
    "COUNT_OF_PREMIUM_COMPANIES_TO_SHOW": 12,
    "COUNT_OF_RECOMMENDATIONS_FOR_PREMIUM_GALLERY": 3
} as const;
export const JobDescriptionResolver = {
    "LIST_ON_PROFILE_PAGE_SIZE": 10
} as const;
export const MatchingQuestionnaireQuestions = {
    "ATMOSPHERE": "atmosphere",
    "INDEPENDENCE": "independence",
    "ENVIRONMENT": "environment",
    "WORK_LIFE_BALANCE": "workLifeBalance",
    "TEAM": "team",
    "PERSONAL_CONTRIBUTION": "personalContribution",
    "SUITABLE_FOR": "suitableFor",
    "FIELD_OF_WORK": "fieldOfWork",
    "DESCRIBE_SITUATION": "describeSituation"
} as const;
export const ProfileContract = {
    "TYPE_CONNECT": "connect",
    "TYPE_BASIC": "basic",
    "TYPE_MEDIUM": "medium",
    "TYPE_PREMIUM": "premium",
    "TYPE_NO_CONTRACT": "normal",
    "CONTRACT_TYPES_ORDERED_FROM_LOWEST": [
        "connect",
        "basic",
        "medium",
        "premium"
    ]
} as const;
export const ProfileImpressionListData = {
    "GTM_ATM_PAID_VARIANT": "atm-premium",
    "GTM_ATM_UNPAID_VARIANT": "atm-classic"
} as const;
export const ProfileShowcaseFacade = {
    "MIN_VISUAL_CONTENT_ITEM_POSITION": 1,
    "MAX_VISUAL_CONTENT_ITEM_POSITION": 3
} as const;
export const ProfileUrlGenerator = {
    "TAB_NAME_SUMMARY": "SUMMARY",
    "TAB_NAME_COMMENTS": "COMMENTS",
    "TAB_NAME_ATMOSPHERE": "ATMOSPHERE",
    "TAB_NAME_JOB_OPPORTUNITIES": "JOB_OPPORTUNITIES",
    "TAB_NAME_GALLERY": "GALLERY",
    "TAB_NAME_PROFILE_SHOWCASE": "PROFILE_SHOWCASE",
    "TAB_SLUGS_BY_TAB_NAME_AND_LOCALE": {
        "SUMMARY": {
            "cs": "",
            "en": "",
            "pl": ""
        },
        "COMMENTS": {
            "cs": "hodnoceni",
            "en": "hodnoceni",
            "pl": "opinie"
        },
        "ATMOSPHERE": {
            "cs": "statistika",
            "en": "statistika",
            "pl": "statystyka"
        },
        "JOB_OPPORTUNITIES": {
            "cs": "nabidky-prace",
            "en": "nabidky-prace",
            "pl": "oferty-pracy"
        },
        "GALLERY": {
            "cs": "galerie",
            "en": "galerie",
            "pl": "galeria"
        },
        "PROFILE_SHOWCASE": {
            "cs": "medailonky",
            "en": "medailonky",
            "pl": "medailonky"
        }
    },
    "PROFILE_VISUAL_CONTENT_TYPE_TO_PROFILE_ADDITIONAL_PARAMETERS": {
        "photo": {
            "_tabName": "SUMMARY"
        },
        "hero": {
            "_tabName": "SUMMARY"
        },
        "summary": {
            "_tabName": "SUMMARY",
            "_fragment": "main"
        },
        "comments": {
            "_tabName": "COMMENTS",
            "_fragment": "main"
        },
        "culture": {
            "_tabName": "ATMOSPHERE",
            "_fragment": "main"
        },
        "showcase": {
            "_tabName": "PROFILE_SHOWCASE",
            "_fragment": "main"
        }
    }
} as const;
export const ProfileVisualContentType = {
    "LOGO": "logo",
    "COVER": "photo",
    "HERO": "hero",
    "SUMMARY": "summary",
    "CULTURE": "culture",
    "COMMENTS": "comments",
    "SHOWCASE": "showcase",
    "PROFILE_VISUAL_CONTENT_TYPES": [
        "photo",
        "hero",
        "summary",
        "culture",
        "comments"
    ],
    "PROFILE_GALLERY_TYPES": [
        "hero",
        "summary",
        "culture",
        "comments"
    ],
    "PROFILE_GALLERY_TYPES_WITH_TITLE_AT_IMAGES": [
        "hero",
        "culture",
        "comments"
    ]
} as const;
export const QuestionnaireSourceType = {
    "SOURCE_EMAIL": "email",
    "SOURCE_ATMOSKOP": "atmoskop-web",
    "SOURCE_EMAIL_ADD_NEW_REVIEW_REMINDER": "email-add-new-review-reminder",
    "SOURCE_FACEBOOK": "fb",
    "SOURCE_INTRODUCTION_PAGE": "uvod",
    "SOURCE_UNKNOWN": "unknown"
} as const;
export const RpsConstants = {
    "LIST_ON_JOBS_CZ_FROM_ATMOSKOP_FOR_NON_PREMIUM": 390,
    "LIST_ON_PRACE_CZ_FROM_ATMOSKOP_FOR_NON_PREMIUM": 425,
    "FALLBACK_DETAIL_ON_ATMOSKOP_CZ": 427,
    "COMPANY_SEARCH_RECOMMENDATION_ON_HOMEPAGE": 417,
    "COMPANY_SEARCH_RECOMMENDATION_DEFAULT": 418,
    "INTERESTING_COMPANIES_FROM_MATEJ": 419,
    "INTERESTING_COMPANIES_DEFAULT": 420,
    "PROFILE_GALLERY": 423,
    "GROUP_MAIN_LIST": "jd-list",
    "GROUP_PROFILE": "profile",
    "GROUP_AGENT": "agent",
    "RPS_BY_GROUP_AND_AGGREGATION_SOURCE": {
        "jd-list": {
            "ATMOSKOP": 474,
            "JOBS_CZ": 477,
            "PRACE_CZ": 480,
            "CAREER_PAGES": 483,
            "OTHER": 483
        },
        "profile": {
            "ATMOSKOP": 475,
            "JOBS_CZ": 478,
            "PRACE_CZ": 481,
            "CAREER_PAGES": 484,
            "OTHER": 484
        },
        "agent": {
            "ATMOSKOP": 476,
            "JOBS_CZ": 479,
            "PRACE_CZ": 482,
            "CAREER_PAGES": 485,
            "OTHER": 485
        }
    }
} as const;
export const SourceReportDataFromBIMetricsNames = {
    "COUNT_OF_COMPANY_BRAND_VIEWS": "COMPANY_BRAND_VIEWS",
    "PROFILE_PAGEVIEW_RANK": "PROFILE_PAGEVIEW_RANK",
    "PROFILE_PAGEVIEW_RANK_NO_PREMIUM": "NO_PREMIUM_RANK",
    "COUNT_OF_TOTAL_PROFILE_PAGEVIEWS": "PROFILE_PAGEVIEWS",
    "COUNT_OF_PROFILE_PAGEVIEWS_FROM_PAID_PROMOTION_ON_ATMOSKOP_CZ": "PROFILE_PAGEVIEWS_PREMIUM",
    "COUNT_OF_PROFILE_PAGEVIEWS_FROM_JOBS_CZ": "JOBS_VISITS_CNT",
    "COUNT_OF_PROFILE_PAGEVIEWS_FROM_PRACE_CZ": "PRACE_VISITS_CNT",
    "COUNT_OF_JOB_DESCRIPTION_VIEWS": "JOB_DESCRIPTION_VIEWS",
    "COUNT_OF_JOB_DESCRIPTION_APPLICATIONS": "NUMBER_OF_APPLICATIONS",
    "TIME_ON_PROFILE_PAGE": "TIME_ON_PROFILE_PAGE",
    "AVERAGE_REVIEW_VALUE_IN_STARS": "AVERAGE_REVIEW_VALUE",
    "COUNT_OF_ADDED_QUESTIONNAIRES": "REVIEWS_CNT",
    "TOTAL_COUNT_OF_FOLLOWERS": "SUBSCRIPTIONS_CNT_CUM",
    "PAID_PROFILE_PAGEVIEW_INCREASE_RATE": "PAGEVIEW_INCREASE_RATE",
    "PAY_HAPPINESS_AVERAGE_REVIEW_VALUE_IN_STARS": "SALARY_AVERAGE_REVIEW_VALUE",
    "RECOMMENDATION_AVERAGE_REVIEW_VALUE_IN_STARS": "RECOMMENDATION_AVERAGE_REVIEW_VALUE",
    "COUNT_OF_COMPANY_BRAND_VIEWS_CUMULATIVE": "COMPANY_BRAND_VIEWS_CUM",
    "PROFILE_PAGEVIEW_RANK_AVERAGE": "PROFILE_PAGEVIEW_RANK_AVG",
    "PROFILE_PAGEVIEW_RANK_NO_PREMIUM_AVERAGE": "NO_PREMIUM_RANK_AVG",
    "COUNT_OF_JOB_DESCRIPTION_VIEWS_CUMULATIVE": "JOB_DESCRIPTION_VIEWS_CUM",
    "COUNT_OF_JOB_DESCRIPTION_APPLICATIONS_CUMULATIVE": "NUMBER_OF_APPLICATIONS_CUM",
    "TIME_ON_PROFILE_PAGE_AVERAGE": "TIME_ON_PROFILE_PAGE_AVG",
    "AVERAGE_REVIEW_VALUE_IN_STARS_CUMULATIVE": "AVERAGE_REVIEW_VALUE_CUM",
    "COUNT_OF_ADDED_QUESTIONNAIRES_CUMULATIVE": "REVIEWS_CNT_CUM",
    "SUM_OF_ALL_ANSWER_VALUES": "ANSWER_VALUE",
    "COUNT_OF_ANSWERED_QUESTIONS_EXCEPT_SALARY_AND_RECOMMENDATION": "QUESTION_CNT"
} as const;
export const SuitableForID = {
    "OPTION_RECOMMEND_50_PLUS": 38,
    "OPTION_RECOMMEND_ATMOSPHERE": 22,
    "OPTION_RECOMMEND_BENEFITS": 64,
    "OPTION_RECOMMEND_CALM": 34,
    "OPTION_RECOMMEND_COMPLEX_TASKS": 28,
    "OPTION_RECOMMEND_FLEXIBLE": 21,
    "OPTION_RECOMMEND_FOREIGN_LANGUAGES": 20,
    "OPTION_RECOMMEND_INDEPENDENCE": 31,
    "OPTION_RECOMMEND_INFORMAL": 30,
    "OPTION_RECOMMEND_INTERNATIONAL_ENV": 63,
    "OPTION_RECOMMEND_JUNIOR": 36,
    "OPTION_RECOMMEND_LEARNING": 29,
    "OPTION_RECOMMEND_MEANINGFULL": 32,
    "OPTION_RECOMMEND_NO_OVERTIME": 24,
    "OPTION_RECOMMEND_ORDER": 27,
    "OPTION_RECOMMEND_PARENTS": 37,
    "OPTION_RECOMMEND_PROMOTION": 23,
    "OPTION_RECOMMEND_STABLE": 33,
    "OPTION_RECOMMEND_STUDENTS": 26,
    "OPTION_RECOMMEND_VARIOUS": 35,
    "OPTION_RECOMMEND_YOUNG_COLLEAGUES": 25,
    "SUITABLE_FOR_OPTIONS": {
        "OPTION_RECOMMEND_NO_OVERTIME": 24,
        "OPTION_RECOMMEND_ATMOSPHERE": 22,
        "OPTION_RECOMMEND_PARENTS": 37,
        "OPTION_RECOMMEND_STABLE": 33,
        "OPTION_RECOMMEND_MEANINGFULL": 32,
        "OPTION_RECOMMEND_CALM": 34,
        "OPTION_RECOMMEND_PROMOTION": 23,
        "OPTION_RECOMMEND_ORDER": 27,
        "OPTION_RECOMMEND_COMPLEX_TASKS": 28,
        "OPTION_RECOMMEND_LEARNING": 29,
        "OPTION_RECOMMEND_INDEPENDENCE": 31,
        "OPTION_RECOMMEND_FLEXIBLE": 21,
        "OPTION_RECOMMEND_VARIOUS": 35,
        "OPTION_RECOMMEND_JUNIOR": 36,
        "OPTION_RECOMMEND_YOUNG_COLLEAGUES": 25,
        "OPTION_RECOMMEND_INTERNATIONAL_ENV": 63,
        "OPTION_RECOMMEND_FOREIGN_LANGUAGES": 20,
        "OPTION_RECOMMEND_STUDENTS": 26,
        "OPTION_RECOMMEND_50_PLUS": 38,
        "OPTION_RECOMMEND_INFORMAL": 30,
        "OPTION_RECOMMEND_BENEFITS": 64
    },
    "TILE_SIZE_EXTRA_SMALL": "extra-small",
    "TILE_SIZE_SMALL": "small",
    "TILE_SIZE_MEDIUM": "medium",
    "TILE_SIZE_LARGE": "large",
    "SUITABLE_FOR_HOMEPAGE_TILE_SIZES": {
        "24": "medium",
        "22": "medium",
        "37": "large",
        "33": "medium",
        "32": "medium",
        "34": "small",
        "23": "small",
        "27": "small",
        "28": "small",
        "29": "small",
        "31": "small",
        "21": "medium",
        "35": "medium",
        "36": "small",
        "25": "small",
        "63": "small",
        "20": "small",
        "26": "small",
        "38": "small",
        "30": "medium",
        "64": "medium"
    },
    "SUITABLE_FOR_URL_SLUGS": {
        "24": "ne-prescasum",
        "22": "fajn-prostredi",
        "37": "rodina",
        "33": "stabilita-a-spolehlivost",
        "32": "smysluplna-prace",
        "34": "hlavne-klid",
        "23": "karierni-rust",
        "27": "rad-ne-chaos",
        "28": "narocne-vyzvy",
        "29": "ucit-se-a-posouvat",
        "31": "samostatnost",
        "21": "volna-pracovni-doba",
        "35": "ne-rutine",
        "36": "prace-i-pro-nezkusene",
        "25": "absolventi-a-zkuseni",
        "63": "mezinarodni-prostredi",
        "20": "nejen-cestina",
        "26": "prace-i-pri-studiu",
        "38": "starsi-50-i-mladi",
        "30": "neformalni-atmosfera",
        "64": "netradicni-benefity"
    }
} as const;
export const UserAccountConstants = {
    "MINIMAL_PASSWORD_LENGTH": 8
} as const;
export const YoutubeVideoLinkHelper = {
    "YOUTUBE_VIDEO_LINK_REGEXP": "%^(?:https?:\\\/\\\/)?(?:www\\.)?(?:m\\.)?(?:youtube\\.com?\\\/(?:watch\\?v=|embed\\\/|v\\\/)|youtu\\.be\\\/)([a-zA-Z0-9_-]{11})(?:[?&#].*)?$%"
} as const;
