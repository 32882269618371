import { Button } from '@lmc-eu/spirit-web-react/components/Button';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import { useModal } from '@hooks/useModal';
import CrossSVG from '@icons/cross-deprecated.svg';
import classes from './Modal.module.scss';

const Modal = () => {
  const { setModal, modal } = useModal();

  const nextRootElement = typeof document !== 'undefined' ? document.getElementById('__next') : null;

  const resolveModalSizeClass = () => {
    const modalSize = modal?.options.size ?? 'medium';

    switch (modalSize) {
      case 'large':
        return classes.Modal__large;
      case 'medium':
      default:
        return '';
    }
  };

  return (
    <ReactModal
      id={modal?.options.id}
      isOpen={Boolean(modal)}
      onRequestClose={() => setModal(null)}
      contentLabel={modal?.options.title}
      overlayClassName={classes.ModalOverlay}
      className={classNames(
        classes.Modal,
        resolveModalSizeClass(),
        modal?.options.withButtons && classes.ModalWithButtons,
      )}
      {...(nextRootElement !== null ? { appElement: nextRootElement } : {})}
      bodyOpenClassName={classes.Modal__bodyOpen}
    >
      <div className={classes.Modal__closeButtonWrapper}>
        <Button onClick={() => setModal(null)} color="secondary">
          <CrossSVG />
        </Button>
      </div>
      {modal?.options.title && (
        <>
          <div className={classes.Modal__title} data-cy="modal-title">
            {modal?.options.title}
          </div>
          <hr />
        </>
      )}
      <div data-cy="modal-content">{modal?.content}</div>
    </ReactModal>
  );
};

export default Modal;
